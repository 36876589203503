<template>
  <v-app id="inspire">
    <v-container fluid class="pa-6">
      <!-- Header Section -->
      <v-row class="mb-8">
        <v-col cols="12">
          <div class="d-flex align-center">
            <img
              height="72"
              width="72"
              src="../assets/gamma_logo.svg"
              class="mr-6"
              style="object-fit: contain"
            />
            <h1 class="text-h3 font-weight-bold mb-1 d-flex align-center">
              Gamma
              <v-chip
                color="primary"
                label
                class="text-caption font-weight-bold ml-4"
                >v2.26</v-chip
              >
            </h1>
          </div>
        </v-col>
      </v-row>

      <!-- First Row - 3 Enhanced Metrics -->
      <v-row>
        <v-col
          v-for="(metric, i) in firstRowMetrics"
          :key="i"
          cols="12"
          sm="6"
          md="4"
        >
          <v-hover v-slot="{ isHovering }">
            <v-card
              :elevation="isHovering ? 8 : 2"
              :class="[
                'transition-swing',
                'metric-card',
                'navigation-card',
                isHovering ? 'gradient-hover' : '',
              ]"
              @click="$router.push(metric.to)"
            >
              <v-card-text class="d-flex flex-column pa-6">
                <!-- Header -->
                <div class="d-flex align-center mb-4">
                  <v-icon
                    :class="['metric-icon', isHovering ? 'icon-hover' : '']"
                    size="32"
                  >
                    {{ metric.icon }}
                  </v-icon>
                  <span class="text-subtitle-1 font-weight-medium ml-2">{{
                    metric.name
                  }}</span>
                </div>

                <!-- Metric -->
                <div class="text-center my-4">
                  <span class="text-h2 font-weight-bold">{{
                    metric.metric
                  }}</span>
                  <div class="text-caption text-medium-emphasis">
                    {{ metric.subtitle }}
                  </div>
                </div>

                <!-- Footer -->
                <div class="mt-auto pt-2">
                  <v-divider></v-divider>
                  <div class="d-flex align-center justify-space-between mt-2">
                    <div class="d-flex align-center">
                      <v-icon small :color="metric.statusColor" class="mr-1">
                        {{ metric.statusIcon }}
                      </v-icon>
                      <span class="text-caption">{{ metric.status }}</span>
                    </div>
                    <v-btn text small color="primary" class="px-0">
                      Ver detalles
                      <v-icon right small>mdi-arrow-right</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>

      <!-- Second Row - 4 Metrics -->
      <v-row class="mt-6">
        <v-col
          v-for="(metric, i) in secondRowMetrics"
          :key="i"
          cols="12"
          sm="6"
          md="3"
        >
          <v-hover v-slot="{ isHovering }">
            <v-card
              :elevation="isHovering ? 8 : 2"
              :class="[
                'transition-swing',
                'metric-card',
                'navigation-card',
                isHovering ? 'gradient-hover' : '',
              ]"
              @click="$router.push(metric.to)"
            >
              <v-card-text class="d-flex flex-column pa-6">
                <!-- Header and Metric -->
                <div class="d-flex justify-space-between align-center mb-4">
                  <v-icon
                    :class="['metric-icon', isHovering ? 'icon-hover' : '']"
                    size="48"
                  >
                    {{ metric.icon }}
                  </v-icon>
                  <span class="text-h3 font-weight-bold">{{
                    metric.metric
                  }}</span>
                </div>

                <!-- Title -->
                <div class="text-subtitle-1 font-weight-medium">
                  {{ metric.name }}
                </div>

                <!-- Footer -->
                <div class="mt-auto pt-2">
                  <v-divider></v-divider>
                  <div class="text-caption text-medium-emphasis mt-2">
                    Click para ver detalles
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>

      <!-- Third Row - User Activity Statistics -->
      <v-row v-if="canViewLeaderboard" class="mt-6">
        <!-- User Interactions Card -->
        <v-col cols="12" md="6">
          <v-card elevation="2" class="metric-card">
            <v-card-title class="d-flex align-center pa-6">
              <div class="d-flex align-center">
                <v-icon size="32" color="success" class="mr-3"
                  >mdi-account-group</v-icon
                >
                <div>
                  <div class="text-h6 font-weight-bold">
                    Actividad de Proyecto
                  </div>
                  <div class="text-caption text-medium-emphasis">
                    Registro de acciones por usuario
                  </div>
                </div>
              </div>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-list class="py-0">
                <v-list-item
                  v-for="(stat, index) in userInteractionStats"
                  :key="index"
                  class="user-activity-item"
                >
                  <v-list-item-avatar>
                    <v-avatar :color="getAvatarColor(stat.username)" size="40">
                      <span class="white--text text-subtitle-2">{{
                        stat.username.charAt(0).toUpperCase()
                      }}</span>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">
                      {{ stat.username }}
                      <span class="text-caption text-medium-emphasis ml-2">
                        {{ stat.log_action_name }}
                      </span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-chip
                        x-small
                        label
                        :color="getActionColor(stat.log_action_name)"
                        class="mt-1"
                      >
                        {{ stat.action_count }} acciones
                      </v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Draft Usage Statistics Card -->
        <v-col cols="12" md="6">
          <v-card elevation="2" class="metric-card">
            <v-card-title class="d-flex align-center pa-6">
              <div class="d-flex align-center">
                <v-icon size="32" color="success" class="mr-3"
                  >mdi-chart-timeline-variant</v-icon
                >
                <div>
                  <div class="text-h6 font-weight-bold">Uso de Borradores</div>
                  <div class="text-caption text-medium-emphasis">
                    Actividad por períodos de tiempo
                  </div>
                </div>
              </div>
            </v-card-title>
            <v-card-text class="pa-0 draft-usage-container">
              <v-list class="py-0 draft-usage-list">
                <v-list-item
                  v-for="(stat, index) in draftUsageStats"
                  :key="index"
                  class="user-activity-item"
                >
                  <v-list-item-avatar>
                    <v-avatar :color="getAvatarColor(stat.username)" size="40">
                      <span class="white--text text-subtitle-2">{{
                        stat.username.charAt(0).toUpperCase()
                      }}</span>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-medium d-flex align-center"
                    >
                      {{ stat.username }}
                      <span v-if="hasRecentActivity(stat)" class="ml-1"
                        >🏆</span
                      >
                      <v-chip
                        x-small
                        outlined
                        class="ml-2"
                        :color="getLastActivityColor(stat.last_interaction)"
                      >
                        {{ getRelativeTime(stat.last_interaction) }}
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle class="mt-2 d-flex align-center">
                      <div class="activity-period mr-4">
                        <div class="text-caption text-medium-emphasis mb-1">
                          24h
                        </div>
                        <v-chip
                          x-small
                          label
                          :color="getActivityColor(stat.ratio_24h_7d)"
                        >
                          {{ stat.activities_last_24h }}
                        </v-chip>
                      </div>
                      <div class="activity-period mr-4">
                        <div class="text-caption text-medium-emphasis mb-1">
                          7d
                        </div>
                        <v-chip
                          x-small
                          label
                          :color="getActivityColor(stat.ratio_7d_30d)"
                        >
                          {{ stat.activities_last_7d }}
                        </v-chip>
                      </div>
                      <div class="activity-period">
                        <div class="text-caption text-medium-emphasis mb-1">
                          30d
                        </div>
                        <v-chip
                          x-small
                          label
                          :color="getActivityColor(stat.ratio_30d_60d)"
                        >
                          {{ stat.activities_last_30d }}
                        </v-chip>
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<style scoped>
.navigation-card {
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid rgba(var(--v-border-color), 0.05);
  background: #f8f9fa;
  transform: translateY(0);
}

.navigation-card:hover {
  transform: translateY(-8px) scale(1.02);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.12) !important;
}

.navigation-card.gradient-hover {
  background: linear-gradient(135deg, #2e7d32, #4caf50);
  color: white;
}

.metric-card {
  background: #f8f9fa;
}

.metric-icon {
  color: #2e7d32;
  opacity: 0.9;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.icon-hover {
  color: white !important;
  transform: scale(1.2) rotate(5deg);
}

.v-card {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.dashboard-metric {
  transition: transform 0.2s ease;
}

.dashboard-metric:hover {
  transform: translateY(-4px);
}

/* New styles for enhanced cards */
.v-card__text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text-h2 {
  line-height: 1.2;
}

.v-divider {
  opacity: 0.1;
  margin: 12px 0 !important;
}

.text-caption {
  opacity: 0.7;
}

.text-subtitle-1 {
  font-size: 1rem !important;
  line-height: 1.5;
}

.text-h3 {
  line-height: 1.2;
}

.v-list-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.v-list-item:last-child {
  border-bottom: none;
}

.v-chip {
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0 8px !important;
  height: 24px !important;
  background-color: #f5f5f5 !important; /* Light grey background */
  color: rgba(0, 0, 0, 0.87) !important; /* Standard text color */
}

.v-chip.v-chip--outlined {
  padding: 0 10px !important;
  background-color: transparent !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
}

/* Override Vuetify's default chip colors */
.v-chip::before {
  background-color: transparent !important;
}

.v-chip:not(.v-chip--outlined) {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.activity-period {
  text-align: center;
  margin: 0 8px;
}

.activity-period:first-child {
  margin-left: 0;
}

.activity-period:last-child {
  margin-right: 0;
}

.activity-period .text-caption {
  margin-bottom: 6px !important;
}

.user-activity-item {
  border-left: 3px solid transparent;
  transition: all 0.2s ease;
}

.user-activity-item:hover {
  border-left-color: var(--v-primary-base);
  background: rgba(0, 0, 0, 0.02);
}

.draft-usage-container {
  height: 600px; /* Increased from 400px to 600px */
  overflow: hidden;
}

.draft-usage-list {
  height: 100%;
  overflow-y: auto;
}

/* Add smooth scrollbar styling */
.draft-usage-list::-webkit-scrollbar {
  width: 8px;
}

.draft-usage-list::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.draft-usage-list::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.draft-usage-list::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HomePage",

  mounted() {
    document.title = "Gamma | Gestión Financiera";
    this.$store.dispatch("getHomepageMetrics");
  },

  computed: {
    ...mapGetters(["homepageMetrics", "userTokenInfo"]),
    firstRowMetrics() {
      return [
        {
          name: "Campañas",
          to: "/abstract-campaign",
          icon: "mdi-calendar",
          metric: this.homepageMetrics?.campaigns || 0,
          subtitle: "Campañas activas",
          status: "Sistema actualizado",
          statusIcon: "mdi-check-circle",
          statusColor: "success",
        },
        {
          name: "Borradores",
          to: "/draft-engagement",
          icon: "mdi-pencil",
          metric: this?.homepageMetrics?.draft_engagements || 0,
          subtitle: "Borradores creados",
          status: "Sistema actualizado",
          statusIcon: "mdi-check-circle",
          statusColor: "success",
        },
        {
          name: "Establecimientos",
          to: "/establishment",
          icon: "mdi-home",
          metric: this?.homepageMetrics?.establishments || 0,
          subtitle: "Establecimientos registrados",
          status: "Sistema actualizado",
          statusIcon: "mdi-check-circle",
          statusColor: "success",
        },
      ];
    },
    secondRowMetrics() {
      return [
        {
          name: "Insumos/Servicios",
          to: "/cost-items",
          icon: "mdi-crop",
          metric: this?.homepageMetrics?.direct_slice_cost_items || 0,
        },
        {
          name: "Costos Toneladas",
          to: "/other-ton-cost-items",
          icon: "mdi-weight",
          metric: this?.homepageMetrics?.ton_cost_items || 0,
        },
        {
          name: "Politicas Financieras",
          to: "/financial-policy",
          icon: "mdi-file-document",
          metric: this?.homepageMetrics?.financial_policies || 0,
        },
        {
          name: "Actividades y Especies",
          to: "/activity-and-species",
          icon: "mdi-sprout",
          metric:
            (this?.homepageMetrics?.activities || 0) +
            (this?.homepageMetrics?.species || 0),
        },
      ];
    },
    userInteractionStats() {
      return this.homepageMetrics?.user_interaction_statistics || [];
    },
    draftUsageStats() {
      return this.homepageMetrics?.draft_usage_statistics || [];
    },
    canViewLeaderboard() {
      console.log("========= Leaderboard Visibility Check =========");
      console.log("Full userTokenInfo:", this.userTokenInfo);
      const userType = this.userTokenInfo?.utype;
      // Allow both directors and admins to view the leaderboard
      const canView = userType === "director" || userType === "admin";
      console.log(`User type "${userType}" can view: ${canView}`);
      console.log("=============================================");
      return canView;
    },
  },

  methods: {
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    },

    getActivityColor(ratio) {
      // No activity
      if (ratio === 0) return 'grey';
      
      // Activity increased or stayed same
      if (ratio >= 1) return 'success';
      
      // Activity decreased but still present
      if (ratio > 0) return 'warning';
      
      // Default case
      return 'default';
    },

    getAvatarColor(username) {
      // Material Design colors selected for good contrast with white text
      const colors = [
        "#1976D2", // Blue
        "#0097A7", // Cyan
        "#388E3C", // Green
        "#7B1FA2", // Purple
        "#00897B", // Teal
        "#5E35B1", // Deep Purple
        "#3949AB", // Indigo
        "#039BE5", // Light Blue
        "#43A047", // Green
        "#C0CA33", // Lime
        "#8E24AA", // Purple
        "#3949AB", // Indigo
      ];

      // More deterministic hash function
      const hash = username.split("").reduce((acc, char, i) => {
        return acc + char.charCodeAt(0) * (i + 1);
      }, 0);

      return colors[Math.abs(hash) % colors.length];
    },

    getActionColor() {
      return "default";
    },

    getLastActivityColor() {
      return "default";
    },

    getRelativeTime(dateString) {
      if (!dateString) {
        return "N/A";
      }

      const diff = new Date() - new Date(dateString);
      const hours = diff / (1000 * 60 * 60);

      if (hours < 24) {
        return `Hace ${Math.round(hours)}h`;
      } else if (hours < 48) {
        return "Ayer";
      } else if (hours < 168) {
        return `Hace ${Math.round(hours / 24)}d`;
      } else {
        return this.formatDate(dateString);
      }
    },

    hasRecentActivity(stat) {
      const lastInteraction = new Date(stat.last_interaction);
      const sixDaysAgo = new Date();
      sixDaysAgo.setDate(sixDaysAgo.getDate() - 6);
      // Only show trophy if there's recent activity AND they have some activity in the last 7 days
      return lastInteraction >= sixDaysAgo && (stat.activities_last_24h > 0 || stat.activities_last_7d > 0);
    },
  },
};
</script>
